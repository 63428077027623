import setupVideoPlayback from '../components/setupVideoPlayback'

const videoHandler = (videoBlock) => {

    videoBlock.querySelectorAll('.play-button').forEach(button => {
        const videoElement = button.closest('.video-preview').nextElementSibling
        setupVideoPlayback(button, videoElement)
    })
}

export default videoHandler

const setupVideoPlayback = (playButton, videoElement) => {
    playButton.addEventListener('click', () => {
        const preview = playButton.closest('.video-preview')
        const container = videoElement
        const youtubeUrl = preview.getAttribute('data-youtube-url')
        preview.style.display = 'none'
        container.style.display = 'block'
        const iframe = container.querySelector('.youtube-video')

        iframe.src = `${youtubeUrl}&autoplay=1&playsinline=1&mute=1`
    })
}

export default setupVideoPlayback
